<template>
    <div class="page p-grid">
        <div class="p-col-12">
            <Breadcrumb :model="items"/>
        </div>
        <div style="height: 500px" class="p-col-12 p-grid p-mt-6">
            <div class="p-col-4"></div>
            <div class="p-col-4">
                <div class="p-mt-6">
                    <div class="p-p-2" style="border: 1px solid #999">
                        <div>
                            <div style="font-size: 18px; font-weight: bold">{{from.content}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-grid p-col-12 p-mt-6 p-jc-around  ">
            <Button @click="notAdopt" label="驳回" class="p-button-outlined p-button-secondary" />
            <Button @click="adopt" label="通过" class="p-button-outlined p-button-secondary" />
        </div>
    </div>
    <Toast/>
</template>

<script>
    export default {
        mounted() {
              this.showResumeDetails()
        },
        name: "",
        data() {
            return {
                items: [
                    {label: '智库推送', to: '/ResumeExamine'},
                    {label: '履历审核', to: '/ResumeExamine'},
                    {label: '详情', to: '/ResumeDetails'},

                ],
                id: this.$route.query.id,
                from: {
                    content: null,
                }
            }
        },
        methods: {
            showResumeDetails() {
                console.log(this.id)
                this.$http.get('/RecordCheck/showResumeDetails',{params:{'id':this.id}}).then((res) =>{
                    this.from = res.data
                    console.log(res.data)
                })
            },

            adopt() {
                let me = this;
                this.$http.get('/RecordCheck/resumeAdopt',{params:{'id':this.id}}).then(() =>{
                    me.$toast.add({severity: 'success', summary: '成功', detail: '操作成功！', life: 3000})
                    setTimeout(function (){
                        me.$router.push('/ResumeExamine')
                    },1000)
                })
            },

            notAdopt (){
                this.$router.push('/ResumeExamine')
            }
        }
    }
</script>

<style scoped>

</style>